import {
  SiTypescript,
  SiReact,
  SiNodedotjs,
  SiGit,
  SiJira,
  SiOpenai,
  SiExpress,
  SiSupabase,
  SiStyledcomponents,
  SiTestinglibrary,
  SiAnthropic,
  SiGooglecolab,
  SiMongodb,
  SiRedux,
  SiFirebase,
  SiTailwindcss,
  SiPostgresql,
  SiDocker,
  SiPostman,
} from "react-icons/si";
import { IconType } from "react-icons";

interface Technology {
  name: string;
  icon: IconType;
  category: "Frontend" | "Backend" | "Tools/Other";
  color: string;
}

export const TECHNOLOGIES: Technology[] = [
  // Frontend
  {
    name: "TypeScript",
    icon: SiTypescript,
    category: "Frontend",
    color: "#3178C6",
  },
  { name: "React", icon: SiReact, category: "Frontend", color: "#61DAFB" },
  {
    name: "Redux",
    icon: SiRedux,
    category: "Frontend",
    color: "#764ABC",
  },
  {
    name: "Styled Components",
    icon: SiStyledcomponents,
    category: "Frontend",
    color: "#DB7093",
  },
  {
    name: "Tailwind CSS",
    icon: SiTailwindcss,
    category: "Frontend",
    color: "#06B6D4",
  },
  {
    name: "Playwright",
    icon: SiTestinglibrary,
    category: "Frontend",
    color: "#2EAD33",
  },

  // Backend
  { name: "Node.js", icon: SiNodedotjs, category: "Backend", color: "#339933" },
  { name: "Express", icon: SiExpress, category: "Backend", color: "#000000" },
  { name: "MongoDB", icon: SiMongodb, category: "Backend", color: "#4DB33D" },
  { name: "PostgreSQL", icon: SiPostgresql, category: "Backend", color: "#4169E1" },
  { name: "Firebase", icon: SiFirebase, category: "Backend", color: "#FFCA28" },
  { name: "Supabase", icon: SiSupabase, category: "Backend", color: "#3ECF8E" },

  // Tools/Other
  { name: "Git", icon: SiGit, category: "Tools/Other", color: "#F05032" },
  {
    name: "Docker",
    icon: SiDocker,
    category: "Tools/Other",
    color: "#2496ED",
  },
  {
    name: "Postman",
    icon: SiPostman,
    category: "Tools/Other",
    color: "#FF6C37",
  },
  {
    name: "Anthropic APIs",
    icon: SiAnthropic,
    category: "Tools/Other",
    color: "#5436DA",
  },
  {
    name: "OpenAI APIs",
    icon: SiOpenai,
    category: "Tools/Other",
    color: "#412991",
  },
  {
    name: "Google Functions",
    icon: SiGooglecolab,
    category: "Tools/Other",
    color: "#4285F4",
  },
  {
    name: "Mongo Atlas",
    icon: SiMongodb,
    category: "Tools/Other",
    color: "#4DB33D",
  },
];
